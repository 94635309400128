@use '../styles/common' as *;

nav {
    flex: none;
    padding: $gap 0 !important;
    background-color: $app-black;

    >.container-fluid{
        align-items: flex-start !important;
        justify-content: space-between;
        &.login{
            justify-content: center;
        }
        .btn{
            padding: $gap/2;
            border: 0;
            border-radius: 0;
            background-color: $app-black !important;
            &:focus {
                box-shadow: none;
                -webkit-tap-highlight-color: transparent;
            }
            >i.bi{
                display: block;
                margin: 0;
                padding: 0;
                width: $gap*2;
                height: $gap*2;
                color: $app-beige;
                &:before{
                    scale: 1.5;
                }
            }
        }
    }

    .navbar-brand{
        flex: 1;
        max-width: $max-width/2;
        margin: 0 auto;
        padding: 0;
        img{
            display: block;
            margin: 0;
            padding: 0;
            width: 100%;
            height: auto;
        }
        +.dropdown{
            // -- temp menu
            // visibility: hidden;
            // pointer-events: none;
            // --
            .dropdown-menu{
                text-align: right;
            }
        }
    }

    .dropdown {
        button {
            &:after {
                display: none;
            }
        }
        .dropdown-menu{
            padding: $gap/2 0;
            border: $gap/4 solid $app-beige;
            border-radius: 0;
            background-color: $app-black;
            >a{
                padding: $gap/2 $gap;
                @include Groches;
                color: $app-beige;
                background-color: $app-black;
                &:hover, &:focus{
                    background-color: $app-black;
                }
            }
        }
    }

    // .navbar-toggler {
    //     border: 0;
    //     outline: none;

    //     &:focus {
    //         box-shadow: none;
    //         -webkit-tap-highlight-color: transparent;
    //     }
    // }

    // .navbar-collapse {
    //     .navbar-nav {
    //         @include Groches;
    //         text-align: end;
    //         margin-top: $gap;

    //         a {
    //             padding: 0;
    //         }
    //     }
    // }

    .page-close {
    }
}