@use '../styles/common' as *;

.welcome-mirror{
    flex: 1;
    display: block;
    margin: $gap 0 (-$gap*3);
    padding: $gap $gap $gap*3;
    text-align: center;
    color: $app-black;
    background-color: $app-beige;
    h1{
        @include Groches;
        margin-bottom: $gap/2;
        +p{
            margin-bottom: 0;
        }
    }
    >i.bi{
        display: block;
        margin: 0 auto $gap !important;
        padding: 0;
        width: $gap*3 !important;
        height: $gap*3 !important;
        color: $app-red;
        &:before{
            scale: 2.25 !important;
            transform-origin: top center;
        }
    }
    >i.done{
        display: block;
        margin: $gap/2 auto;
        width: $gap*6;
        height: $gap*6;
        color: $app-red;
    }
    .popover-trigger{
        width: 100% !important;
        display: block !important;
        margin: 0 !important;
        padding: 0 !important;
        border: 0 !important;
        background-color: transparent !important;
        color: $app-black !important;
    }
}