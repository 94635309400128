@use "common" as *;

@import "elements";

#root {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  @include Montserrat;
  color: $app-beige;
  background-color: $app-black;
  @include media-breakpoint-up(sm) {
    max-width: $max-width;
    max-height: $max-height;
    min-height: $max-width * 1.333;
    box-shadow: $box-shadow-lg;
  }
  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $app-black;
    .container {
      padding: $gap;
    }
  }

  .mb-3{
    margin-bottom: $gap !important;
  }

  ~.modal-backdrop {
    &.show {
      background-color: $app-alpha75-black !important;
      opacity: 1;
    }
  }
  ~.modal{
    .modal-dialog {
      margin: 0 auto;
      padding: 0 $gap;
      max-width: $max-width;
      .modal-content {
        color: $app-beige;
        background-color: $app-black;
        border-radius: 0;
        @include Montserrat;
        box-shadow: $box-shadow-lg;
      }
      .modal-body{
        padding: $gap $gap $gap/2;
        .page-title {
          @include Groches;
          margin: 0 0 $gap/2;
          text-align: center;
        }
        .page-subtitle {
          padding: 0 ;
          text-align: center;
          margin: 0;
          &.error{
            color: $app-red;
            +p{
              margin-top: $gap/2;
              @include MontserratSmall;
            }
          }
        }
      }
      .modal-footer {
        padding: 0 0 $gap;
        border: 0;
      }
      .btn {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin: $gap;
        padding: $gap/2 $gap;
        width: calc(100% - $gap * 2);
        min-height: $gap * 3;
        border: $gap/4 solid transparent;
        border-radius: 0;
        box-sizing: border-box;
        @include Groches;
        text-align: center;
        &.btn-primary {
          color: $app-beige;
          background-color: $app-red;
          border-color: $app-beige;
          margin-bottom: 0;
          &:hover {
            border-color: $app-beige;
          }
        }
        &.btn-secondary {
          color: $app-beige;
          background-color: $app-black;
          border-color: $app-beige;
          &:disabled {
            color: $app-black;
            background-color: $app-beige;
            border-color: $app-black;
            opacity: 1;
          }
          &:hover {
            border-color: $app-beige;
          }
        }
        &:focus {
          box-shadow: none;
          -webkit-tap-highlight-color: transparent;
          outline: none;
        }
      }
    }
  }
}
