@use '../styles/common' as *;

.form-check{
    min-height: $gap*2;
    margin: 0;
    @include MontserratSmall;
}

.account-fields{
    .invalid-feedback{
        margin-bottom: $gap !important;
    }
}
.account-checks{
}