@use '../styles/common' as *;


.container-fluid.game{
    flex: 1;
    display: flex;
    >.row{
        flex: 1 1 100%;
        >.col{
            display: flex;
            flex-direction: column;
        }
    }
}

.page-title{
    &.title-gaming{
        margin-top: $gap;
    }
}

.ligafangames-logo{
    margin: $gap;
}

.game-mirror{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: $gap 0 (-$gap*3);
    padding: $gap $gap $gap*3;
    text-align: center;
    color: $app-black;
    background-color: $app-beige;
    h2{
        @include Groches;
        color: $app-red;
        margin-bottom: $gap/2;
    }
    .prize-congrats{
        margin: 0 auto;
        padding: 0;
        // max-width: $max-width/1.5;
        // @include MontserratBold;
    }
    .prize-congrats-sub{
        margin: $gap/2 auto 0;
        padding: 0;
        // max-width: $max-width/1.5;
    }
    img{
        display: block;
        width: 100%;
        height: auto;
        margin: 0 0 $gap;
    }
    .game-subtitle{
        margin: 0 auto $gap/2;
        padding: 0;
        // max-width: $max-width/1.5;
        // @include MontserratBold;
    }
    .game-description{
        margin: 0 auto;
        padding: 0;
        // max-width: $max-width/1.5;
    }
    >.progress{
        border-radius: 0;
        border: $gap/4 solid $app-beige;
        background-color: $app-beige;
        height: $gap*1.5;
        margin-bottom: $gap/2;
        .progress-bar{
            background-color: $app-red;
        }
    }
    small{
        display: none;
        @include media-breakpoint-up(sm) {
            display: block;
            margin-bottom: $gap;
            color: $app-red;
            @include MontserratBold;
        }
    }
    p{
        display: block;
        margin: $gap auto 0;
        padding: 0;
        // max-width: $max-width/1.5;
        @include MontserratBold;
        &:not(.todo){
            display: none;
            +.game-form{
                display: none;
            }
        }
    }
    i.prize{
        display: block;
        margin: $gap*2 auto $gap/2;
        width: $gap*6;
        height: $gap*6;
        color: $app-black;
        svg{
            // scale: 2;
            // transform-origin: top center;
        }
    }
    .copy-hashtag{
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        margin: $gap auto;
        padding: $gap/2 $gap;
        border: 1px solid $app-beige;
        // text-transform: uppercase;
        cursor: pointer;
        .bi{
            display: flex !important;
            align-items: center;
            justify-content: center;
            margin-left: $gap/2 !important;
        }
    }
    .prize-name{
        @include Groches;
    }
    .prize-detail{
        display: block;
        margin: $gap auto 0;
        padding: 0;
        // max-width: $max-width/1.5;
        @include MontserratBold;
    }
    .game-countdown{
        padding: $gap;
        &-remaining{
            color: $app-red;
        }
        &-counter{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            @include GrochesBigger;
            color: $app-red;
            >div{
                display: flex;
                flex-direction: column;
                margin: 0 $gap/2;
                min-width: $gap*3.5;
                span{
                    @include MontserratSmall;
                }
            }
            
        }
    }
    .game-form{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        form{
            margin: auto 0;
            .form-check{
                display: block;
                margin: 0;
                padding: 0;
                input{
                    display: none;
                    +label{
                        display: block;
                        margin: $gap 0;
                        padding: $gap/2 $gap;
                        width: 100%;
                        min-height: $gap*3;
                        border: $gap/4 solid $app-black;
                        border-radius: 0;
                        box-sizing: border-box;
                        @include Groches;
                        text-align: center;
                        color: $app-black;
                        background-color: $app-beige;
                    }
                    &:checked{
                        +label{
                            color: $app-beige;
                            background-color: $app-black;
                        }
                    }
                }
            }
        }
    }
    &.invert{
        flex: none;
        padding-top: 0;
        color: $app-beige;
        background-color: $app-black;
        // border: $gap/4 solid $app-beige;
        .prize{
            margin: $gap auto $gap/2;
            width: $gap*8;
            height: $gap*8;
        }
        .prize-name{
           @include GrochesBigger;
        }
        .prize-detail{
            margin-top: $gap/2;
        }
        .game-form{
            form{
                padding: 0 !important;
                .form-check{
                    input{
                        +label{
                            border-color: $app-beige;
                            color: $app-beige;
                            background-color: $app-black;
                        }
                        &:checked{
                            +label{
                                color: $app-black;
                                background-color: $app-beige;
                            }
                        }
                    }
                }
            }
        }
    }
    form{
        margin-top: $gap !important;
        padding: 0 !important;
        text-align: left;
    }
}