@import "../../node_modules/bootstrap/scss/bootstrap";
@import "typography";

$gap: $grid-gutter-width/2;
// $max-width: map-get($container-max-widths, sm);
// $max-width: $modal-md;
$max-width: $offcanvas-horizontal-width;
$max-height: $max-width*1.777;


$app-white: #ffffff;
$app-grey: #ccc;
$app-black: #1A1919;
$app-red: #C73734;
$app-beige: #E4D7B4;
$app-alpha75-black: rgba($app-black, .75);
$app-alpha50-black: rgba($app-black, .50);

html,
body {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-width: $modal-sm;
    min-height: $max-width*1.333;
    align-items: center;
    justify-content: center;
    background-color: $app-black;
    @include media-breakpoint-up(sm) {
        // background-color: $app-grey;
    }
}

body{
    // background-repeat: no-repeat;
    // background-position: center;
    // // background-size: cover;
    // @include media-breakpoint-down(sm) {
    //     background-image: none !important;
    // }
    #bkg{
        display: block;
        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center;
        // background-size: cover;
        @include media-breakpoint-down(sm) {
            background-image: none !important;
        }
    }

    .popover[role=tooltip]{
        border: 0;
        box-shadow: none;
        .popover-arrow{
            box-shadow: none;
            &:before{
                display: none;
            }
            &:after{
                border-top-color: $app-beige;
            }
        }
        .popover-body{
            padding: $gap/2 $gap;
            border: 0;
            border-radius: 0;
            box-shadow: none;
            background-color: $app-beige;
            @include MontserratSmall;
            color: $app-black;
            text-align: center;
        }
    }
}