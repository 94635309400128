@use '../styles/common' as *;

.loader{
    position: fixed;
    z-index: 9999;
    display: flex;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: $app-alpha75-black;
    .spinner-border{
        border-color: $app-beige;
        border-bottom-color: transparent;
    }
}