@font-face {
    font-family: 'Groches';
    src: local('Groches'), url(../fonts/GROCHES.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    
}

// @font-face {
//     font-family: 'Akzidenz';
//     src: local('Akzidenz'), url(../fonts/AkzidenzGrotesk-MediumExtended.otf) format('opentype');
//     font-weight: normal;
//     font-style: normal;
// }

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(../fonts/Montserrat-Medium.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratBold';
    src: local('MontserratBold'), url(../fonts/Montserrat-Bold.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@mixin Groches {
    font-family: Groches, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1;
    text-transform: uppercase;
}

@mixin GrochesBigger {
    @include Groches;
    font-size: 2.2rem !important;
}

// @mixin Akzidenz {
//     font-family: Akzidenz, sans-serif;
//     font-weight: normal;
//     font-style: normal;
//     font-size: 1rem;
//     line-height: 1.25;
// }

@mixin Montserrat {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: .9375rem;
    line-height: 1.25;
    text-transform: none;
}

@mixin MontserratSmall {
    @include Montserrat;
    font-size: .75rem !important;
}

@mixin MontserratSmaller {
    @include Montserrat;
    font-size: .666rem !important;
}

@mixin MontserratBold {
    font-family: MontserratBold, sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: .9375rem;
    line-height: 1.25;
    text-transform: none;
}


h1, h2, h3, h4, h5, h6{
    display: block;
    margin: 0;
    padding: 0;
    @include Groches;
    &.bigger{
       @include GrochesBigger;
    }
}

small{
    @include MontserratSmaller;
}

b, strong{
    @include MontserratBold;
}