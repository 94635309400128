@use '../styles/common' as *;

#root {
    main {
        .info-mirror{
            flex: 1;
            display: block;
            margin: $gap 0 (-$gap*3);
            padding: $gap $gap $gap*3;
            color: $app-black;
            background-color: $app-beige;
            .info-part{
                margin-bottom: $gap;
                .info-head{
                    @include Groches;
                    color: $app-red;
                    margin-bottom: $gap/2;
                }
                .info-title{
                    @include MontserratBold;
                    margin-bottom: $gap/2;
                }
                .info-text{
                    padding-bottom: $gap;
                    a{  
                        @include MontserratBold;
                        color: $app-black !important;
                    }
                    &-note{
                        padding-top: $gap;
                        @include MontserratSmall;
                    }
                }
            }
        }
    }
}