@use '../styles/common' as *;

footer{
    padding: $gap 0;
    background-color: $app-black;

    >.container-fluid{
        .row{
            +.row{
                margin-top: $gap/2;
            }
            .col{
                small{
                    display: block;
                    margin: 0;
                    padding: 0;
                    @include media-breakpoint-down(sm) {
                        // padding: 0 $gap*4;
                    }
                }
                a{
                    color: $app-beige !important;
                    &.powered-by{
                        display: flex;
                        width: fit-content;
                        align-items: center;
                        justify-content: center;
                        margin: $gap/2 auto 0 auto;
                        text-decoration: none;
                        img{
                            display: block;
                            height: $gap;
                            width: auto;
                            border: none;
                        }
                    }
                }
                display: flex;
                justify-content: center;
                text-align: center;
                .logo{
                    position: relative;
                    width: 100%;
                    height: $gap*4;
                    overflow: hidden;
                    svg{
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}