@use '../styles/common' as *;

.qr-reader{
    position: relative;
    flex: 1;
    margin: 0;
    padding: 0;
    video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: $gap;
        object-fit: cover;
        +.scan-region-highlight{
            display: block !important;
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            width: 50% !important;
            height: auto !important;
            margin: 0 !important;
            padding: 0 0 50% 0 !important;
            svg{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                stroke: $app-red !important;
            }
        }
        ~.scan-region-highlight{
            display: none;
        }
    }
}

.scan-me{
    padding: 0 $gap $gap;
    @include Groches;
    text-align: center;
    color: $app-beige;
}
