@use 'common' as *;


#root {
    main {

        .page-title{
            @include Groches;
            margin-bottom: $gap/2;
            text-align: center;
        }
        
        .page-subtitle {
            padding: 0 $gap;
            text-align: center;
            &.text-danger{
                @include MontserratSmall;
                color: $app-red;
            }
        }

        // icons
        i.bi {
            display: block;
            margin: 0;
            padding: 0;
            width: $gap*2;
            height: $gap*2;
        }

        // buttons
        .btn {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            margin: $gap;
            padding: $gap/2 $gap;
            width: calc(100% - $gap*2);
            min-height: $gap*3;
            border: $gap/4 solid transparent;
            border-radius: 0;
            box-sizing: border-box;
            @include Groches;
            text-align: center;

            &.btn-primary {
                color: $app-beige;
                background-color: $app-red;
                border-color: $app-beige;

                &:hover {
                    // border-color: transparent;
                    border-color: $app-beige;
                }
            }

            &.btn-secondary {
                color: $app-beige;
                background-color: $app-black;
                border-color: $app-beige;

                &:disabled {
                    color: $app-black;
                    background-color: $app-beige;
                    border-color: $app-black;
                    opacity: 1;
                }

                &:hover {
                    border-color: $app-beige;
                }
            }

            &:focus {
                box-shadow: none;
                -webkit-tap-highlight-color: transparent;
                outline: none;
            }

            >i.bi {
                order: 999;
                margin-left: auto;
            }

            &.btn-grid {
                position: relative;
                flex-wrap: wrap;
                padding-left: $gap*5;
                min-height: $gap*5;
                text-align: left;
                justify-content: flex-start;

                >i.bi,
                >i.ci {
                    position: absolute;
                    left: $gap;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: $gap*3;
                    height: $gap*3;
                    color: $app-beige;

                    &:before {
                        scale: 1.5;
                        transform-origin: top left;
                    }
                }

                small {
                    flex: 1 1 100%;
                    @include MontserratSmall;
                }
            }
        }

        // forms
        form {
            margin: 0;
            padding: 0 $gap;

            .input-group{
                margin: -$gap 0;
                .input-group-text{
                    border-radius: 0;
                    border: 0;
                    margin: $gap $gap/4 $gap 0;
                    background-color: $app-beige;
                    +div{
                        flex: 1;
                    }
                }
            }

            input{
                &::-webkit-input-placeholder, &:-ms-input-placeholder, &:-moz-placeholder, &::-moz-placeholder {
                    color: $app-alpha50-black;
                    opacity: 1;
                }
                &:-webkit-autofill, &:-webkit-autofill:focus {
                    transition: background-color 600000s 0s, color 600000s 0s;
                }
                &[data-autocompleted] {
                    background-color: transparent !important;
                }
                &.form-control {
                    margin: $gap 0;
                    padding: $gap/2 $gap;
                    width: 100%;
                    min-height: $gap*3.5;
                    border: $gap/4 solid $app-beige;
                    border-radius: 0;
                    // box-sizing: border-box;
                    @include Montserrat;
                    color: $app-black !important;
                    background-color: $app-beige;
    
                    &:focus {
                        border-color: $app-beige;
                        background-color: $app-beige;
                        box-shadow: none !important;
                        -webkit-tap-highlight-color: transparent;
                        outline: none;
                    }
    
                    &.is-invalid {
                        border-color: $app-red;
                    }
    
                    &#password {
                        padding-right: $gap*3;
    
                        &.is-invalid {
                            background-image: none;
                        }
    
                        +button {
                            background-color: transparent;
                            margin: 0;
                            padding: 0;
                            border: 0;
                            color: $app-black;
                            // background-size: 1.25rem;
                            position: absolute;
                            right: 0;
                            top: 0%;
                            // transform: translateY(50%);
                            width: $gap*3.5;
                            height: $gap*3.5;
                        }
                    }
    
                    ~.invalid-feedback {
                        margin: -$gap/2 0 0;
                        @include MontserratSmall;
                        color: $app-red;
                    }
                }
                &[type=checkbox]{
                    border: 0;
                    border-radius: 0;
                    background-color: $app-beige;
                    &:checked{
                        background-color: $app-red;
                    }
                    &:focus{
                        box-shadow: none !important;
                        -webkit-tap-highlight-color: transparent;
                        outline: none;
                    }
                    &.is-invalid{
                        +label{
                            color: $app-red;
                            a{
                                color: $app-red !important;
                            }
                        }
                    }
                }
            }

            .form-check{
                display: flex;
                align-items: baseline;
                input{
                    margin-right: $gap/2;
                }
            }

            .form-floating{
                .form-control{
                    height: $gap*3.5;
                    min-height: $gap*3.5;
                    padding: $gap $gap 0;
                    &:focus, &:not(:placeholder-shown){
                        ~label{
                            @include MontserratSmaller;
                            line-height: $gap*1.5;
                            transform: scale(1);
                        }
                    }
                }
                >label{
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: $app-alpha50-black;
                    overflow: visible;
                    border: 0;
                    margin: 0;
                    margin-left: $gap*1.25;
                    padding: 0;
                    line-height: $gap*3.5;
                    height: auto;
                    transition: all .1s ease-in-out;
                    &:after{
                        background-color: transparent;
                    }
                }
            }

            .password-field {
                position: relative;
                // margin-top: $gap*2;
                input::-ms-reveal, input::-ms-clear {
                    display: none;
                }
            }

            .btn {
                margin: $gap 0;
                width: 100%;
            }
        }

        a {
            color: $app-beige;
        }
        
    }
}